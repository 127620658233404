import React, { useEffect, useState } from "react";
import { Accordion, Button, InputText } from "@bluesilodev/timhutcomponents";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { DeleteSvg, FiPlusCircle, FiSaveSvg } from "components/icons";
import { settingSchema } from "./settingSchema";
import { useEditSetting, useGetSettingByOrganizationId } from "service/settingAPI";
import { alertError, alertSuccess } from "utils/alert";

function SuperAdminSetting() {
    // GET DATA SETTING
    const { data, isSuccess } = useGetSettingByOrganizationId("", {
        onSuccess: () => {},
        onError: () => {},
    });
    // UPDATE DATA SETTING
    const { mutate, isPending: loadingUpdate } = useEditSetting({
        onSuccess: () => {
            alertSuccess("Data Success Updated");
        },
        onError: () => {
            alertError("Data Failure Updated!");
        },
    });
    const initialValue = {
        uId: "",
        type: "",
        organizationID: "",
        hobbies: [],
        skills: [],
        typeOfAllowances: [],
        isActive: true,
    };
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: settingSchema,
        onSubmit: () => {
            const formData = {};
            formData.organizationID = values.organizationID;
            formData.hobbies = values.hobbies;
            formData.skills = values.skills;
            formData.typeOfAllowances = values.typeOfAllowances;
            formData.isActive = values.isActive;
            mutate(formData);
        },
    });
    useEffect(() => {
        if (isSuccess) {
            const setToApi = {
                uId: data.data.uId ?? "",
                type: data.data.type ?? "",
                organizationID: data.data.organizationID ?? "",
                hobbies: data.data.hobbies ?? [],
                skills: data.data.skills ?? [],
                typeOfAllowances: data.data.typeOfAllowances ?? [],
                isActive: data.data.isActive ?? true,
            };
            setValues(setToApi);
        }
    }, [data]);
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        setFieldTouched,
        setValues,
        errors,
        touched,
    } = formik;
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="overflow-y-auto"
                >
                    <div className="w-full flex justify-end pt-5">
                        <Button
                            style="solid"
                            className={"w-[200px]"}
                            type="submit"
                            label={
                                <div className="flex gap-2">
                                    <FiSaveSvg />
                                    <div>Save</div>
                                </div>
                            }
                        />
                    </div>
                    <div className="py-5">
                        <Accordion
                            title={"Hobbies"}
                            icons={[]}
                            children={
                                <FieldArray name="hobbies">
                                    {({ push, remove }) => (
                                        <div className="w-full flex flex-col gap-2 pt-3">
                                            {typeof errors.hobbies == "string" && <div className="text-red-500">{errors.hobbies}</div>}
                                            {values.hobbies.map((item, index) => (
                                                <div key={index} className="flex gap-2 w-full">
                                                    <div className="w-[60%] flex gap-3">
                                                        <InputText
                                                            label={null}
                                                            title={"Hobby Name"}
                                                            id={`hobbies.${index}`}
                                                            value={values.hobbies[index]}
                                                            onChange={(e) => {
                                                                setFieldValue(`hobbies.${index}`, e.target.value);
                                                            }}
                                                            onBlur={() => setFieldTouched(`hobbies.${index}`, true)}
                                                            error={
                                                                errors.hobbies && errors.hobbies[index] &&
                                                                touched.hobbies && touched.hobbies[index] && (
                                                                    <div className="text-red-500">
                                                                        {errors.hobbies[index]}
                                                                    </div>
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => remove(index)}
                                                        className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                                                            index > -1 ? "block" : "hidden"
                                                        }`}
                                                    >
                                                    <DeleteSvg />
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                type={"button"}
                                                onClick={() => push("")}
                                                label={
                                                    <div className="flex gap-2">
                                                        <FiPlusCircle color={"#A3A3AB"} />
                                                        <div className="text-white">Add More Hobby</div>
                                                    </div>
                                                }
                                                className={"w-[60%] mt-2 btn-secondary-solid"}
                                                style="solid"
                                            />
                                        </div>
                                    )}
                                </FieldArray>
                            }
                        />
                    </div>
                    <div className="py-5">
                        <Accordion
                            title={"Skills"}
                            icons={[]}
                            children={
                                <FieldArray name="skills">
                                    {({ push, remove }) => (
                                        <div className="w-full flex flex-col gap-2 pt-3">
                                            {typeof errors.skills == "string" && <div className="text-red-500">{errors.skills}</div>}
                                            {values.skills.map((item, index) => (
                                                <div key={index} className="flex gap-2 w-full">
                                                    <div className="w-[60%] flex gap-3">
                                                        <InputText
                                                            label={null}
                                                            title={"Skill Name"}
                                                            id={`skills.${index}`}
                                                            value={values.skills[index]}
                                                            onChange={(e) => {
                                                                setFieldValue(`skills.${index}`, e.target.value);
                                                            }}
                                                            onBlur={() => setFieldTouched(`skills.${index}`, true)}
                                                            error={
                                                                errors.skills && errors.skills[index] &&
                                                                touched.skills && touched.skills[index] && (
                                                                    <div className="text-red-500">
                                                                        {errors.skills[index]}
                                                                    </div>
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => remove(index)}
                                                        className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                                                            index > -1 ? "block" : "hidden"
                                                        }`}
                                                    >
                                                    <DeleteSvg />
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                type={"button"}
                                                onClick={() => push("")}
                                                label={
                                                    <div className="flex gap-2">
                                                        <FiPlusCircle color={"#A3A3AB"} />
                                                        <div className="text-white">Add More Skill</div>
                                                    </div>
                                                }
                                                className={"w-[60%] mt-2 btn-secondary-solid"}
                                                style="solid"
                                            />
                                        </div>
                                    )}
                                </FieldArray>
                            }
                        />
                    </div>
                    <div className="py-5">
                        <Accordion
                            title={"Type Of Allowance"}
                            icons={[]}
                            children={
                                <FieldArray name="typeOfAllowances">
                                    {({ push, remove }) => (
                                        <div className="w-full flex flex-col gap-2 pt-3">
                                            {typeof errors.typeOfAllowances == "string" && <div className="text-red-500">{errors.typeOfAllowances}</div>}
                                            {values.typeOfAllowances.map((item, index) => (
                                                <div key={index} className="flex gap-2 w-full">
                                                    <div className="w-[60%] flex gap-3">
                                                        <InputText
                                                            label={null}
                                                            title={"Type Of Allowance Name"}
                                                            id={`typeOfAllowances.${index}`}
                                                            value={values.typeOfAllowances[index]}
                                                            onChange={(e) => {
                                                                setFieldValue(`typeOfAllowances.${index}`, e.target.value);
                                                            }}
                                                            onBlur={() => setFieldTouched(`typeOfAllowances.${index}`, true)}
                                                            error={
                                                                errors.typeOfAllowances && errors.typeOfAllowances[index] &&
                                                                touched.typeOfAllowances && touched.typeOfAllowances[index] && (
                                                                    <div className="text-red-500">
                                                                        {errors.typeOfAllowances[index]}
                                                                    </div>
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => remove(index)}
                                                        className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                                                            index > -1 ? "block" : "hidden"
                                                        }`}
                                                    >
                                                    <DeleteSvg />
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                type={"button"}
                                                onClick={() => push("")}
                                                label={
                                                    <div className="flex gap-2">
                                                        <FiPlusCircle color={"#A3A3AB"} />
                                                        <div className="text-white">Add More Type Of Allowance</div>
                                                    </div>
                                                }
                                                className={"w-[60%] mt-2 btn-secondary-solid"}
                                                style="solid"
                                            />
                                        </div>
                                    )}
                                </FieldArray>
                            }
                        />
                    </div>
                </form>
            </FormikProvider>
        </React.Fragment>
    )
}

export default SuperAdminSetting;
