import React from "react";
import {
  Accordion,
  InputSelect,
  InputDate,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";

import SelectChoice from "components/SelectChoice";
import { useGetAllEmployeeStatus } from "service/employeeStatusAPI";
import { useGetAllLocation } from "service/locationAPI";
import { useGetAllUser } from "service/userAPI";
import { adminRole, adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

function EmployeeMentDetailShare({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) {
  const [stringDepartmentData, setStringDepartmentData] = React.useState("");
  const [departmentData, setDepartmentData] = React.useState([]);
  const [jobPosition, setJobPosition] = React.useState([]);
  const queryClient = useQueryClient();

  // GET ALL EMPLOYEE STATUS DATA
  const { data: employeeStatus, isPending: pendingEmployeeStatus } =
    useGetAllEmployeeStatus({
      onSuccess: () => {
        queryClient.invalidateQueries("getEmployeeStatus");
      },
    });

  // GET ALL LOCATION
  const { data: location, isPending: pendingLocation } = useGetAllLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
    },
  });

  // GET ALL USER
  const { data: userData, isPending: pendingAllUser } = useGetAllUser(
    { getAll: true },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUser");
      },
      onError: (err) => {
        console.log("err : ", err);
      },
    }
  );

  React.useEffect(() => {
    setStringDepartmentData(values.employeementDetail.departments)
  }, [values.employeementDetail.departments])

  // MAPPING DEPARTMENT
  React.useEffect(() => {
    const departmentTempData = location?.data.filter((item) => item.uId === values.employeementDetail.locations).flatMap((entry) => entry.departments).map((item, index) => {
      return {
        label: item.department,
        value: item.department,
      };
    });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    })
    setDepartmentData(departmentFInal);
  }, [values.employeementDetail.locations, location?.data]);

  // MAPPING JOB POSITION
  React.useEffect(() => {
    const jobPositionData = location?.data.filter((item) => item.uId === values.employeementDetail.locations).flatMap((depart) => {
      return depart.departments.filter((item) => item.department === stringDepartmentData).flatMap((partIdx) => {
        return partIdx.jobPosition.map((item, index) => {
          return {
            label: item,
            value: item,
          };
        });
      });
    });
    const jobPositionFinal = [];
    jobPositionData?.forEach((item) => {
      // console.log("testtest", item, jobPosition)
      if (!jobPositionFinal.some((single) => single.label === item.label)) {
        jobPositionFinal.push({
          label: item.label,
          value: item.value,
        });
      }
    })
    setJobPosition(jobPositionFinal);
  }, [values.employeementDetail.locations, values.employeementDetail.departments, stringDepartmentData, location?.data]);

  if (pendingAllUser || pendingLocation || pendingEmployeeStatus) {
    return <div>Loading...</div>;
  }

  function makeUnique(arr) {
    if (!arr) return [];
    const seen = new Set();
    return arr.filter(obj => {
      const stringified = JSON.stringify(obj);
      if (seen.has(stringified)) {
        return false;
      } else {
        seen.add(stringified);
        return true;
      }
    });
  }

  // LEAVE DATA

  // MAPPING EMPLOYEE STATUS
  const employeeStatusMap = employeeStatus?.data.map((item, index) => {
    return {
      label: item.employeeType,
      value: item.uId,
    };
  });
  // LOCATION
  const locationData = makeUnique(location?.data).map((item, index) => {
    return {
      label: item.locationName,
      value: item.uId,
    };
  });

  const userMapData = userData?.data.map((item, index) => {
    const firstName = item?.firstName;
    const location = item?.locations?.[0]?.locationName;
    const department = item?.userInformation?.employeementDetail?.departments;
    return {
      label: `${firstName} (${location} - ${department})`,
      value: item.uId,
    };
  });

  const handleDelete = (positionIndex, setFieldValue, value) => {
    const data = value.filter((_, idx) => idx !== positionIndex);
    setFieldValue(`role`, data);
  };

  return (
    <>
      <Accordion
        title={"Employement Details"}
        icons={[]}
        children={
          <div className={`w-full pt-3 flex flex-col gap-2 py-10 `}>
            <div className="flex gap-2">
              <InputSelect
                title={"Employee Status"}
                required={true}
                options={employeeStatusMap}
                id="employeementDetail.employeeStatus"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.employeementDetail.employeeStatus}
                error={
                  <div>
                    {errors.employeementDetail &&
                      errors.employeementDetail.employeeStatus &&
                      touched.employeementDetail &&
                      touched.employeementDetail.employeeStatus &&
                      errors.employeementDetail.employeeStatus}
                  </div>
                }
              />

              <InputDate
                label={"Join Date"}
                name={"employeementDetail.joinDate"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.employeementDetail.joinDate}
                setFieldValue={setFieldValue}
                required={true}
                errors={
                  errors.employeementDetail &&
                  errors.employeementDetail.joinDate &&
                  touched.employeementDetail &&
                  touched.employeementDetail.joinDate && (
                    <div className="text-red-500 text-sm">
                      {errors.employeementDetail.joinDate}
                    </div>
                  )
                }
              />

              <InputDate
                label={"End Date"}
                name={"employeementDetail.endDate"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.employeementDetail.endDate}
                setFieldValue={setFieldValue}
                required={true}
                errors={
                  errors.employeementDetail &&
                  errors.employeementDetail.endDate &&
                  touched.employeementDetail &&
                  touched.employeementDetail.endDate && (
                    <div className="text-red-500 text-sm">
                      {errors.employeementDetail.endDate}
                    </div>
                  )
                }
              />
            </div>
            <div className="flex gap-2">
              <InputSelect
                title={"Locations"}
                required={true}
                options={locationData}
                id="employeementDetail.locations"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.employeementDetail.locations}
                error={
                  <div>
                    {errors.employeementDetail &&
                      errors.employeementDetail.locations &&
                      touched.employeementDetail &&
                      touched.employeementDetail.locations &&
                      errors.employeementDetail.locations}
                  </div>
                }
              />
              <InputSelect
                title={"Department"}
                required={true}
                options={departmentData}
                id="employeementDetail.departments"
                onChange={(e) => {
                  setStringDepartmentData(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.employeementDetail.departments}
                disabled={!adminRole && true}
                error={
                  <div>
                    {errors.employeementDetail &&
                      errors.employeementDetail.departments &&
                      touched.employeementDetail &&
                      touched.employeementDetail.departments &&
                      errors.employeementDetail.departments}
                  </div>
                }
              />
              <InputSelect
                title={"Job Positions"}
                required={true}
                options={jobPosition}
                id="employeementDetail.jobPosition"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.employeementDetail.jobPosition}
                disabled={!adminRole && true}
                error={
                  <div>
                    {errors.employeementDetail &&
                      errors.employeementDetail.jobPosition &&
                      touched.employeementDetail &&
                      touched.employeementDetail.jobPosition &&
                      errors.employeementDetail.jobPosition}
                  </div>
                }
              />
            </div>
            {/* IF ROLE SUPERVISOR: DISABLE: JOB LEVEL, JOB POSITION, DEPARTMENT */}
            {/* NAMUN DATA NYA YG ADA DIKETIGA ITU AKAN MENGIKUTI UID YANG DIPILIH  */}
            <div className="flex gap-2">
              <InputText
                title={"Employees ID"}
                type={"text"}
                required={true}
                label={null}
                id="employeeID"
                value={values.employeeID}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  <>
                    {errors.employeeID &&
                      touched.employeeID &&
                      errors.employeeID}
                  </>
                }
              />
              <div className="flex gap-3 w-full">
                <div className="w-full">
                  <InputSelect
                    title={"Job Level"}
                    options={[
                      ...["Admin"].some((val) => values.role.includes(val)) ? [] : [{ label: "Admin", value: "Admin" }],
                      ...["Supervisor", "Employee"].some((val) => values.role.includes(val)) ? [] : [{ label: "Supervisor", value: "Supervisor" }],
                      ...["Supervisor", "Employee"].some((val) => values.role.includes(val)) ? [] : [{ label: "Employee", value: "Employee" }],
                    ]}
                    required={true}
                    id="role"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const updateRoles = [...values.role];
                      if (!updateRoles.includes(selectedValue) && selectedValue.length !== 0) {
                        // Periksa apakah nilai sudah ada dalam array
                        updateRoles.push(selectedValue);
                        setFieldValue(`role`, updateRoles);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.role}
                    error={<div>{errors.role && touched.role && errors.role}</div>}
                    disabled={!adminRole && true}
                  />
                  <div className="flex gap-2 mt-2">
                    {values.role.map((position, positionIndex) => (
                      <div className="" key={positionIndex}>
                        <SelectChoice
                          title={position}
                          disabled={!adminRole && true}
                          handleDelete={() => {
                            const value = values.role;
                            handleDelete(positionIndex, setFieldValue, value);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <InputSelect
                title={"Report To"}
                options={userMapData}
                required={false}
                id="reportTo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.reportTo}
                error={
                  <div>
                    {errors.reportTo && touched.reportTo && errors.reportTo}
                  </div>
                }
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default EmployeeMentDetailShare;
