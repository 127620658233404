import React, { useState } from "react";
import {
  Accordion,
  InputSelect,
  InputText,
  InputDate,
  UploadImage,
  CheckBox,
} from "@bluesilodev/timhutcomponents";

import { useGetAllCountry } from "service/commonAPI";
import { useQueryClient } from "@tanstack/react-query";

function IdentifyDocShared({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  fieldPhotoValue,
}) {
  const [originalImg, setOriginalImg] = useState();
  const [attachments, setAttachments] = useState(null);
  const queryClient = useQueryClient();

  // GET THE IMAGE
  const handleChangeImg = (event) => {
    const file = event.target.files[0];

    if (file) {
      !originalImg && setOriginalImg(values?.identificationDocument?.attachments)
      const photo = URL.createObjectURL(file);
      setAttachments(photo);
      setFieldValue(fieldPhotoValue, file);
    }
  };

  // "identificationDocument.attachments"

  const handleDeleteImg = () => {
    if (originalImg) {
      setFieldValue("identificationDocument.attachments", originalImg);
      setOriginalImg();
    }
    setAttachments("");
    // setFieldValue(fieldPhotoValue, "");
  };

  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  if (isPending) {
    return <h1>Loading...</h1>;
  }
  const dataCountry = resCountry.data.map((item, _) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  return (
    <div className="w-full py-5 h-screen">
      <div className="w-full py-5">
        <Accordion
          icons={[]}
          title={"Identification Documents"}
          children={
            <div className="w-[65%] py-3 pl-1 flex flex-col gap-2">
              <div className="flex gap-2">
                <InputSelect
                  title={"Identification Type"}
                  required={true}
                  options={[
                    {
                      label: "Residential Identity Card",
                      value: "Residential Identity Card",
                    },
                    { label: "Driver's License", value: "Driver's License" },
                    { label: "Passport", value: "Passport" },
                    {
                      label: "National Identity Card",
                      value: "National Identity Card",
                    },
                  ]}
                  id="identificationDocument.identificationType"
                  value={values.identificationDocument.identificationType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    <>
                      {errors.identificationDocument &&
                        errors.identificationDocument.identificationType &&
                        touched.identificationDocument &&
                        touched.identificationDocument.identificationType &&
                        errors.identificationDocument.identificationType}
                    </>
                  }
                />
                <InputText
                  title={"Identity Number"}
                  type={"number"}
                  required={true}
                  label={null}
                  id="identificationDocument.identityNumber"
                  value={values.identificationDocument.identityNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    <>
                      {errors.identificationDocument &&
                        errors.identificationDocument.identityNumber &&
                        touched.identificationDocument &&
                        touched.identificationDocument.identityNumber &&
                        errors.identificationDocument.identityNumber}
                    </>
                  }
                />
              </div>

              <div className="flex gap-2">
                <InputDate
                  label={"Identity Expired Date"}
                  setFieldValue={setFieldValue}
                  name={"identificationDocument.identityExpiredDate"}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.identificationDocument.identityExpiredDate}
                  errors={
                    errors.identificationDocument &&
                    errors.identificationDocument.identityExpiredDate &&
                    touched.identificationDocument &&
                    touched.identificationDocument.identityExpiredDate && (
                      <div className="text-red-500 text-sm">
                        {errors.identificationDocument.identityExpiredDate}
                      </div>
                    )
                  }
                />

                <div className="w-full flex justify-start rounded-md">
                  <CheckBox
                    label={"Permanent Date"}
                    value={values.identificationDocument.isPermanentDate}
                    nameInput={"identificationDocument.isPermanentDate"}
                    onChange={(v) => setFieldValue("identificationDocument.isPermanentDate", v)}
                  />
                </div>
              </div>

              <div className="flex gap-2 mt-[10px]">
                <InputSelect
                  title={"Nationality"}
                  required={true}
                  options={dataCountry}
                  id="identificationDocument.nationality"
                  value={values.identificationDocument.nationality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    <>
                      {errors.identificationDocument &&
                        errors.identificationDocument.nationality &&
                        touched.identificationDocument &&
                        touched.identificationDocument.nationality &&
                        errors.identificationDocument.nationality}
                    </>
                  }
                />

                <InputSelect
                  title={"Covid-19 Vacination Status"}
                  options={[
                    { label: "Fully Vaccinated", value: "Fully Vaccinated" },
                    {
                      label: "Partially Vaccinated",
                      value: "Partially Vaccinated",
                    },
                    { label: "Not Vaccinated", value: "Not Vaccinated" },
                  ]}
                  id="identificationDocument.covid19VaksinationStatus"
                  value={values.identificationDocument.covid19VaksinationStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    <>
                      {errors.identificationDocument &&
                        errors.identificationDocument
                          .covid19VaksinationStatus &&
                        touched.identificationDocument &&
                        touched.identificationDocument
                          .covid19VaksinationStatus &&
                        errors.identificationDocument.covid19VaksinationStatus}
                    </>
                  }
                />
              </div>
            </div>
          }
        />
      </div>

      <Accordion
        icons={[]}
        title={"Attachments"}
        children={
          <div className="w-full pt-3">
            <UploadImage
              textSizeLimit={
                "For Best Resolution is 1366 x 1366px, Max Upload image size is 2 MB, Supported files : .jpg, .png, .jpeg"
              }
              id="identificationDocument.attachments"
              onChange={handleChangeImg}
              image={
                !attachments
                  ? values?.identificationDocument?.attachments
                  : attachments
              }
              handleDeleteImg={handleDeleteImg}
              accept="image/jpeg, image/jpg, image/png"
              onBlur={handleBlur}
              error={
                errors.identificationDocument &&
                errors.identificationDocument.attachments &&
                touched.identificationDocument &&
                touched.identificationDocument.attachments &&
                errors.identificationDocument.attachments
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default IdentifyDocShared;
