import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/setting`;

export const useGetSettingByOrganizationId = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getSettingByOrganizationId"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/uId`);
            return res.data;
        },
        onSuccess,
        onError,
    })
}

export const useEditSetting = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}/`, body)
            return res.data;
        },
        onSuccess,
        onError
    });
}
