import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `${process.env.REACT_APP_API_URL}/api/user`;

export const useGetAllUser = (options) => {
    // DESTRUCT TO OPTIONS ALL PARAMS
    const { keyword, location, department, jobPosition, getAll, rowsPerPage, currentPage, ...queryOptions } = options;

    // Membuat fungsi untuk fetch data
    const fetchData = async () => {
        let queryParams = "employee=true&" + (!rowsPerPage ? "limit=9999&" : "");

        // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
        if (rowsPerPage) queryParams += `limit=${rowsPerPage}&`;
        if (currentPage) queryParams += `page=${currentPage}&`;
        if (keyword) queryParams += `keyword=${keyword}&`;
        if (location) queryParams += `location=${location}&`;
        if (department) queryParams += `department=${department}&`;
        if (jobPosition) queryParams += `jobPosition=${jobPosition}&`;
        if (getAll) queryParams += `getAll=${getAll}&`;

        // Menghapus tanda '&' di akhir URL jika diperlukan
        if (queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const url = queryParams ? `${API}?${queryParams}` : API;

        try {
            const res = await customAxios.get(url);

            return res.data;
        } catch (error) {
            console.error("Error fetching data: ", error); // Debug: Log error
            throw error;
        }
    };

    // Menggunakan queryKey default jika tidak ada parameter yang diberikan
    const queryKey = ["getUser"];
    if (rowsPerPage) queryKey.push(`limit=${rowsPerPage}`);
    if (currentPage) queryKey.push(`page=${currentPage}`);
    if (keyword) queryKey.push(`keyword=${keyword}`);
    if (location) queryKey.push(`location=${location}`);
    if (department) queryKey.push(`department=${department}`);
    if (jobPosition) queryKey.push(`jobPosition=${jobPosition}`);

    return useQuery({
        queryKey: queryKey.length > 1 ? queryKey : ["getUser"], // Gunakan queryKey yang sudah dibuat
        queryFn: fetchData,
        ...queryOptions
    });
};

export const useGetUserById = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getUserById", uId],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/${uId}/`)

            return res.data;
        },
        onSuccess,
        onError
    })
}


export const useCreateUser = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};

export const useDeleteUser = (id, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async () => {
            const res = await customAxios.delete(`${API}/${id}`)

            return res;
        },
        onSuccess,
        onError
    });
};

export const useEditUser = (id, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${id}`, body)
            return res.data;
        },
        onSuccess,
        onError
    });
};

export const useEditUserOrganization = (id, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/organization/${id}`, body)
            return res.data;
        },
        onSuccess,
        onError
    });
};


export const useDownloadExcel = ({ onSuccess, onError }) => {

    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.post(`${API}/dowloadExcel`, body, { responseType: 'blob' })
            return res.data;
        },
        onSuccess,
        onError
    });
}



