import React from "react";
import { useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  UserIcon,
  PreviumSvg,
  FiHome,
  FiHeart,
  FiArrowLeft,
  IconSetting,
  Switch,
  ChevronSvg,
} from "components/icons";
import { setLogoutReducer } from "store/userLoginSlice";
import LayoutForm from "pages/shared/layout/LayoutForm";
import { userLoginNow } from "utils/roleFromLocalStorage";


function SuperAdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uId } = useParams();

  React.useEffect(() => {
    if (!userLoginNow?.role?.includes("SuperAdmin")) {
      navigate("/");
    }
  });

  // LOGOUT
  function logOut() {
    dispatch(setLogoutReducer());
    window.location.href = "/login";
    window.localStorage.clear();
    return null;
  }

  const mainPaths = ["/super/organization", "/super/editCompany"];

  const employeeNav = ["/super/employee", "/super/editEmployee"];

  const settingNav = ["/super/setting"];

  const isMatchMain = mainPaths.some((path) => {
    return location.pathname === path || location.pathname.includes(path);
  });

  const isMatchEmployee = employeeNav.some((path) => {
    return location.pathname === path || location.pathname.includes(path);
  });

  const isMatchSetting = settingNav.some((path) => {
    return location.pathname === path || location.pathname.includes(path);
  });

  // CREATE MENU SIDEBAR
  const allMenu = [
    {
      label: (
        <NavLink
          to={"/super/organization"}
          // onClick={() => setPathUserSetting("Company Details")}
          className={`${
            isMatchMain && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <FiHome color={`${isMatchMain ? "white" : "black"}`} />
          <h1>Organization </h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/super/employee"}
          className={`${
            isMatchEmployee && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <UserIcon color={`${isMatchEmployee ? "white" : "black"}`} />
          <h1>Employee</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/super/setting"}
          // onClick={() => setPathUserSetting("Company Details")}
          className={`${
            isMatchSetting && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <IconSetting color={`${isMatchSetting ? "white" : "black"}`} />
          <h1>Setting</h1>
        </NavLink>
      ),
    },
  ];

  const itemSetting = [
    {
      label: (
        <NavLink
          to={"/myAccount"}
          className="py-1 flex items-center hover:bg-gray-100 cursor-pointer"
          role="none"
        >
          <UserIcon color={"black"} />
          <h1 className="text-gray-700 block px-2 py-2 text-xs">My Account</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/setting"}
          className=" flex items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <IconSetting />
          <div
            className="text-gray-700 block px-2 py-2 text-xs"
            tabIndex="-1"
            id="menu-item-3"
          >
            Settings
          </div>
        </NavLink>
      ),
    },
    {
      label: (
        <div
          className="flex items-center gap-1 hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <Switch />
          <div className="w-full flex justify-between">
            <div className="flex flex-col items-start justify-start ">
              <h1
                className="text-gray-700 block text-xs"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-3"
              >
                Language
              </h1>
              <p
                className="text-gray-700  text-xs font-bold"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-3"
              >
                English
              </p>
            </div>

            <div className="mt-3">
              <ChevronSvg />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: (
        <NavLink
          to={"/currentPlan"}
          className=" flex gap-1 items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <PreviumSvg />
          <div className="flex flex-col">
            <h1
              className="text-gray-700 text-xs"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-3"
            >
              Current Plan
            </h1>
            <p
              className="text-gray-700  text-xs font-bold"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-3"
            >
              Free Trial (14 Days Left)
            </p>
          </div>
        </NavLink>
      ),
    },
    {
      label: (
        <div
          className=" flex gap-1 items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
          onClick={logOut}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-[20px] h-[20px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
          <div
            className="text-gray-700  text-xs"
            role="menuitem"
            tabIndex="-1"
            id="menu-item-3"
          >
            Log Out
          </div>
        </div>
      ),
    },
  ];

  const handleBackOrganization = () => {
    navigate("/super/organization");
  };

  const handleBackEmployee = () => {
    navigate("/super/employee");
  };

  const handleTitle = () => {
    if (mainPaths.includes(location.pathname)) {
      return <h1 className="text-[24px]">Organization</h1>;
    } else if (employeeNav.includes(location.pathname)) {
      return <h1 className="text-[24px]">Employee</h1>;
    } else if (settingNav.includes(location.pathname)) {
      return <h1 className="text-[24px]">Setting</h1>;
    }

    if (location.pathname.startsWith("/super/addCompany")) {
      return (
        <div
          onClick={handleBackOrganization}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Add Company</h1>
        </div>
      );
    }
    if (location.pathname.startsWith(`/super/editCompany`)) {
      return (
        <div
          onClick={handleBackOrganization}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Edit Company</h1>
        </div>
      );
    }
    if (location.pathname.startsWith("/super/addEmployee")) {
      return (
        <div
          onClick={handleBackEmployee}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Add Employee</h1>
        </div>
      );
    }
    if (location.pathname.startsWith(`/super/editEmployee`)) {
      return (
        <div
          onClick={handleBackEmployee}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Edit Employee</h1>
        </div>
      );
    }
  };

  return (
    <LayoutForm
      allMenu={allMenu}
      handleTitle={handleTitle}
      itemSetting={itemSetting}
    />
  );
}

export default SuperAdminLayout;
